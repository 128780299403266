<template>
    <v-layout >  
        <!-- //---------------------DIALOG -->
        <div>  
            <!-- KITCHEN NEW ORDER OPTIONS -->
            <v-dialog
                v-model="dialog_new_order_option"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_new_order_option"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">ORDER OPTION</div> 
                        <v-spacer></v-spacer>
                        <!-- <div class="font-weight-bold b1--text not-f7">mwk{{MBS.actions.money(TotalDiscountedPrice)}}</div> -->
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <div class="mb-2">Order details  </div>
                        <form @submit.prevent="CREATE_NEW_ORDER(input,SELECTED_ORDER_OPTION)"> 
                            <mbs-input-fields class="mx-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.send_order_options"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                <!-- <v-flex xs6>
                                    <div class="mb-2">Attach a Customer</div>
                                    <v-autocomplete  
                                        filled outlined autofocus 
                                        prepend-inner-icon="mdi-account" 
                                        :items="Customers" 
                                        item-text="name"
                                        item-value="key" 
                                        v-model="input.customer_key"
                                        class="mr-1"
                                    />  
                                </v-flex>
                                <v-flex xs6>
                                    <div class="mb-2">Location</div>
                                    <v-text-field  prepend-inner-icon="mdi-map" 
                                        v-model="input.location_name"
                                        filled outlined   clearable
                                        class="m-1"
                                    ></v-text-field> 
                                </v-flex>
                                <v-flex xs12>
                                    <div class="mb-2">Description</div> 
                                    <v-textarea  
                                        v-model="input.description"
                                        filled outlined 
                                        class="m-1"
                                    ></v-textarea> 
                                </v-flex> -->
                                <v-flex sm8>
                                    <v-btn type="submit" large  
                                        @click="PUT_AND_PRINT = false"
                                        :loading="inputLoading"
                                        class="mt-3 "  color="primary" height="60" width="99%">
                                        <div class="not-f5">{{ SELECTED_ORDER_OPTION?SELECTED_ORDER_OPTION.name:'..' }}</div>  
                                    </v-btn>   
                                </v-flex> 
                                <v-flex sm4> 
                                    <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                        v-model="menu_send_and_print_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl">

                                        <template v-slot:activator="{ on }">   
                                             
                                            <v-btn large  v-show="false" type="submit"
                                                :loading="inputLoading" ref="ref_btn_send_and_print"
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                            <v-btn large  v-on="on"
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Print Option
                                            </v-card-title>
                                            <v-list>
                                                <v-list-item type="submit"  @click="SEND_AND_PRINT_NEW_ORDER('print_docket_and_bill')">
                                                    Send & Print Both Docket & Bill
                                                </v-list-item> 
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_docket')">
                                                    Send & Print Docket
                                                </v-list-item>
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_bill')">
                                                    Send & Print Bill
                                                </v-list-item> 
                                            </v-list>
                                        </v-card>
                                    </v-menu> 
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
            
            

            <!-- //print -->
            <div v-if="auto_print.printing"> 
                <mbs-auto-print 
                    @finish_print="FINISH_PRINT"
                    :show="true"  
                    :auto_print="auto_print.auto"
                    :auto_printer_name="auto_print.auto_printer_name"
                    :com_printer="auto_print.com_printer"  
                    :data="auto_print.print_data"
                    :type="auto_print.print_type"
                    :title="auto_print.print_title"
                    :printing_action="auto_print.printing_action"
                    :display_option="auto_print.display_option" 
                    />  
            </div> 
        </div>
    </v-layout>
</template> 
<script>
    let PAGE_NAME = 'ORDER-OPTION'
    import DATA from "../../plugins/DATA"
    import DB from "../../plugins/DB"
    import {mapState,mapGetters } from "vuex" 
    import { toNumber } from '../../plugins/formatNumber'
    export default { 
        props:['show','disabled','hight','width','dense','waiter_uid','item_order','DOWNLOADING_ORDERS','DOWNLOADED_ORDERS'],
        data(){
            return{  
                body_height:0,
                dialog:false, 
                dialog_new_order_option:false, 
                dialog_update_order_option:false, 
                dialog_view_orders_list:false, 
                dialog_view_customers_orders:false, 
                dialog_view_options_orders:false, 
                dialog_view_table_orders:false, 
                dialog_view_selected_customer_order:false, 
                dialog_view_selected_table_order:false, 
                dialog_view_waiter_orders:false, 
                dialog_view_selected_waiter_order:false, 
                dialog_view_selected_option_order:false, 
                dialog_view_order:false, 
                menu_send_and_print_option:false,
                menu_select_order_docket:false,
                menu_select_order_option:false,
                menu_update_order_option:false,
                menu_update_existing_order_option:false,
                currentOrderOptionTab:null,  
                currentItemOrderTab:null,  
                input:{}, 
                inputLoading:false, 
                search:null, 
                menuSelect:false,
                PUT_AND_PRINT:false,
                ORDER_PRINT_OPTIONS:null,
                SELECTED_ORDER_OPTION:null,
                SELECTED_ORDER:null, 
                SELECTED_CUSTOMER_ORDER:null, 
                SELECTED_TABLE_ORDER:null, 
                SELECTED_WAITER_ORDER:null, 
                DATA:DATA,  
                clearing_item_orders:false,

                auto_print:{
                    auto:false,
                    printing:false, 
                    print_data:null,
                    print_type:null,
                    com_printer:false, 
                    print_title:null, 
                    auto_printer_name:null,
                    printing_action:null, 
                    display_option:'',  
                },
                
            } 
        }, 
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
                this.MBS.events.$on('ON_SUBMITTED_ITEM_ORDERS', this.ON_SUBMITTED_ITEM_ORDERS);   
                this.MBS.events.$on('ON_VIEW_ORDERS', this.ON_VIEW_ORDERS);   

                this.MBS.events.$on('CONFIRM_DELETE_ITEM_ORDER', this.CONFIRM_DELETE_ITEM_ORDER);   
                this.MBS.events.$on('CONFIRM_DELETE_ALL_ITEM_ORDER', this.CONFIRM_DELETE_ALL_ITEM_ORDER);   
                this.MBS.events.$on('DELETE_ITEM_ORDER', this.DELETE_ITEM_ORDER);   
                this.MBS.events.$on('DELETE_MULTIPLE_ITEM_ORDERS', this.DELETE_MULTIPLE_ITEM_ORDERS);   
                this.MBS.events.$on('DELETE_ALL_ITEM_ORDER', this.DELETE_ALL_ITEM_ORDER);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){  
        },
        computed:{
            route(){
                return this.$route
            },
            vs(){

                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 


            vsb(){ 
                let body_height = this.body_height 
                let height = this.vs.height 
                body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:body_height;
                let diff = height-body_height
                return {
                    height:height,
                    body_height:body_height,
                    diff:diff,
                    scroll:body_height?diff>180?false:true:false,
                }
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                CompanyUsers: state=> state.users[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],
                JoinedCompanyUsers: state=> state.join[(DATA.ITEMS.COMPANY_USERS.values).toUpperCase()],  
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()], 
                
                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                    JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                    CustomerGroups: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
                    JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalCashups: state=> state.join[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()],  

                LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
    
                OrderOptionTypes: state=> state.app['ODER_OPTION_TYPES'],
            }), 
            CustomersData(){
                const Customers = this.Customers
                const JoinedCustomers = this.JoinedCustomers  
                return JoinedCustomers?JoinedCustomers:Customers
            }, 
            CustomerGroupsData(){
                const CustomerGroups = this.CustomerGroups
                const JoinedCustomerGroups = this.JoinedCustomerGroups  
                return JoinedCustomerGroups?JoinedCustomerGroups:CustomerGroups
            },
            
            
        },
        methods:{  
            TABLE_ACTION(action,item){
                    try { 
                        let route = this.route
                        let fullPath = route?route.fullPath:null
                        console.log(action,'action');
                        if (action == "refresh") { 
                            this.REFRESH_ORDERS()
                        }else if (action == "order_list") { 
                            this.CHANGE_ORDER_LIST_VIEW('list')
                        }else if (action == "customer_orders") { 
                            this.CHANGE_ORDER_LIST_VIEW('customer')
                        }else if (action == "table_orders") { 
                            this.CHANGE_ORDER_LIST_VIEW('table')
                        }else if (action == "waiter_orders") { 
                            this.CHANGE_ORDER_LIST_VIEW('waiter')
                        }else if (action == "delete_all_orders") { 
                            this.CONFIRM_DELETE_ALL_ITEM_ORDER()
                        }else if (action == "exit") { 
                            this.EXIT_DIALOG()
                        } 
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'TABLE_ACTION',
                            page:PAGE_NAME, 
                        }) 
                    } 
            }, 
            
            //-------------[main]
            async DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.input.check = true 
                                    this.MBS.actions.dialogInput({
                                        show:true,
                                        fixed:true,
                                        title:"CONFIRM",
                                        text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                        btnYes:"CONFIRM",
                                        field:[
                                            {field:"password",label:'Password',type:'password'}
                                        ],
                                        action:{
                                            ...action,
                                            code:PAGE_NAME+"=SELECT-COMPANY",
                                        }
                                    })  
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }  
                        } 
                        if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    let password = action.password
                                    password = password?this.MBS.crypt.encrypt(password):null 
                                    if (!password) { 
                                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                    }else if (password!==this.ud.p_uid) { 
                                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                    } else {
                                        this.SELECT_COMPANY(action.data) 
                                    }   
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }   

                        
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-MULTIPLE-ITEM-ORDERS') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_MULTIPLE_ITEM_ORDERS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDERS') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ALL_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);      
        },
        watch:{  
            input(){
                
            }, 
            LocalCashup(value){
                this.CLEAR_ITEM_ORDERS(value)
            }
        }
    }
</script> 